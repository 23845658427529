import { getPath, pageNames } from '@atc/bonnet-paths';
import { getMarket } from '@atc/bonnet-reference';

async function getPathSRP(query = {}, options) {
    const defaultParams = {
        sortBy: 'relevance',
        searchRadius: '50',
        numRecords: 25,
        marketExtension: 'include',
    };

    if (query.zip) {
        const { payload } = await getMarket(query.zip);

        defaultParams.searchRadius = payload?.defaultSearchRadius?.toString();
    }

    // TODO: Figure out why searchRadius becomes an array after a filter change
    if (query.searchRadius && Array.isArray(query.searchRadius)) {
        query.searchRadius = query.searchRadius[0];
    }

    // remove query params with default values
    Object.keys(defaultParams).forEach((key) => {
        if (query[key] && query[key] === defaultParams[key]) {
            delete query[key];
        }
    });

    // remove unnecessary params
    delete query['0'];
    delete query.searchresults; // TODO: BONNET NEXT - is there a better place for this
    delete query.requestId;
    delete query.showAccelerateBanner;
    delete query.newSearch;

    return getPath(pageNames.SEARCH_RESULTS, query, options);
}

export default getPathSRP;
